var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "boxed-layout", staticStyle: { "max-width": "unset" } },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex justify-content-between align-items-baseline mb-3",
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "d-flex align-items-center" },
              [
                _c(
                  "h2",
                  {
                    attrs: { id: "page-heading", "data-cy": "CustomerHeading" },
                  },
                  [_vm._v("Kunden")]
                ),
                _vm._v(" "),
                _c("router-link", {
                  attrs: { to: { name: "CustomerCreate" }, custom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ navigate }) {
                        return [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-sm rounded btn-outline-primary ml-2",
                              attrs: {
                                id: "create-entity",
                                "data-cy": "entityCreateButton",
                              },
                              on: { click: navigate },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "plus" },
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v(" Kunde anlegen ")]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ml-auto d-flex align-items-start" }, [
            _c("div", {}, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _setup.searchText,
                    expression: "searchText",
                  },
                ],
                staticClass: "form-control",
                staticStyle: { width: "300px" },
                attrs: {
                  id: "customer-search",
                  name: "search",
                  placeholder: "Suche (Zuname, Vorname)",
                  type: "text",
                  autocomplete: "off",
                },
                domProps: { value: _setup.searchText },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _setup.searchCustomers()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _setup.searchText = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary ml-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _setup.searchCustomers()
                  },
                },
              },
              [_c("font-awesome-icon", { attrs: { icon: "search" } })],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      !_setup.isFetching && _setup.customers && _setup.customers.length === 0
        ? _c("div", { staticClass: "alert alert-secondary" }, [
            _vm._v("\n    Es wurden keine Kunden gefunden.\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _setup.customers && _setup.customers.length > 0
        ? _c("div", [
            _c(
              "table",
              {
                staticClass: "table table-striped",
                attrs: { "aria-describedby": "customers" },
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_setup.customers, function (customer) {
                    return _c(
                      "tr",
                      { key: customer.id, attrs: { "data-cy": "entityTable" } },
                      [
                        _c("td", [_vm._v(_vm._s(customer.lastName))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(customer.firstName))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm._f("formatGermanDate")(customer.dob))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(customer.job))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(customer.street))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(customer.zip))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(customer.city))]),
                        _vm._v(" "),
                        _c("td", [
                          customer.mainInsured
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "CustomerEdit",
                                          params: {
                                            customerId: customer.mainInsured.id,
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          customer.mainInsured.firstName +
                                            " " +
                                            customer.mainInsured.lastName
                                        ) + "\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _c(
                            "div",
                            { staticClass: "" },
                            [
                              _c("router-link", {
                                attrs: {
                                  to: {
                                    name: "CustomerEdit",
                                    params: { customerId: customer.id },
                                  },
                                  custom: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ navigate }) {
                                        return [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-primary btn-sm edit",
                                              attrs: {
                                                "data-cy": "entityEditButton",
                                              },
                                              on: { click: navigate },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: { icon: "pencil-alt" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.removeEntity",
                                      modifiers: { removeEntity: true },
                                    },
                                  ],
                                  staticClass: "btn btn-sm",
                                  on: {
                                    click: function ($event) {
                                      return _setup.prepareRemove(customer)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "trash" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row justify-content-center" },
              [
                _c("b-pagination", {
                  attrs: {
                    change: _setup.loadPage(_setup.page),
                    "per-page": _setup.itemsPerPage,
                    "total-rows": _setup.totalItems,
                    size: "md",
                  },
                  model: {
                    value: _setup.page,
                    callback: function ($$v) {
                      _setup.page = $$v
                    },
                    expression: "page",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row justify-content-center" }, [
              _c("small", [
                _vm._v(
                  " Es sind insgesamt " +
                    _vm._s(_setup.totalItems) +
                    " Kunden vorhanden."
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        { ref: "removeEntityModal", attrs: { id: "removeEntity" } },
        [
          _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c(
              "span",
              {
                attrs: {
                  id: "cdpRisikoApp.customer.delete.question",
                  "data-cy": "customerDeleteDialogHeading",
                },
              },
              [_vm._v("\n        Löschung Bestätigen\n      ")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("p", { attrs: { id: "gen-delete-customer-heading" } }, [
              _vm._v(
                "\n        Sind Sie sicher, dass Sie diesen Eintrag löschen möchten\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _setup.closeDialog()
                  },
                },
              },
              [_vm._v("Abbrechen")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  id: "gen-confirm-delete-customer",
                  "data-cy": "entityConfirmDeleteButton",
                  type: "button",
                },
                on: {
                  click: function ($event) {
                    return _setup.removeCustomer()
                  },
                },
              },
              [_vm._v("\n        Löschen\n      ")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "row" } }, [_vm._v("Zuname")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "row" } }, [_vm._v("Vorname")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "row" } }, [_vm._v("Geburtsdatum")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "row" } }, [_vm._v("Beruf")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "row" } }, [_vm._v("Strasse")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "row" } }, [_vm._v("PLZ")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "row" } }, [_vm._v("Ort")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "row" } }, [_vm._v("Mitversichert mit")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "row" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }